
import {
    BusinessClient,
    BusinessClientContact,
    BusinessClientDiscountCombinations,
    ClientContactError,
    BusinessClientPhotoInstruction
} from '@/models/BusinessClients';
import BusinessClientOrderProducts from '@/components/BusinessClients/BusinessClientOrderProducts.vue';
import BusinessClientAccounting from '@/components/BusinessClients/BusinessClientAccounting.vue';
import BusinessClientCoupons from '@/components/BusinessClients/BusinessClientCoupons.vue';
import { computed, defineComponent, toRef, ref, PropType } from 'vue';
import { generalStore } from '@/store';
import swal from 'sweetalert2';
import Multiselect from '@vueform/multiselect';
import { useI18n } from 'vue-i18n';
import { Currency, IdName } from '@/models/Interfaces';
import router from '@/router';
import CheckBoxInput from '@/components/CheckBoxInput.vue';
import { SearchSystem } from '@/models/Enums';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { api } from '@/services/Api';
import q from 'q';

export default defineComponent({
    name: 'BusinessClientDetails',
    components: { Multiselect, CheckBoxInput, Cropper, BusinessClientOrderProducts, BusinessClientAccounting, BusinessClientCoupons },
    props: {
        businessClientProp: {
            type: BusinessClient,
            required: true
        },
        editMode: {
            type: Boolean,
            default: false
        },
        clientContactError: {
            type: [] as PropType<ClientContactError[]>,
            default: () => null
        },
        errorsClientDetails: {
            type: Array as PropType<string[]>,
            default: () => []
        },
        contactsHasDuplicatesProp: {
            type: Boolean,
            default: false
        },
        isNew: {
            type: Boolean,
            default: false
        },
        activeBtn: {
            type: Number,
            required: true
        }
    },
    async setup(props, { emit }) {
        swal.showLoading();
        const passwordFieldType = ref('password');
        const searchSystem = SearchSystem;
        const { t } = useI18n();
        const businessClient = toRef(props, 'businessClientProp');
        const contactsHasDuplicates = toRef(props, 'contactsHasDuplicatesProp');
        const businessClientTypes = await generalStore.dispatch('getBusinessClientTypes');
        const officialClubs = await generalStore.dispatch('getOfficialClubs');
        const businessClientTypesTranslations = businessClientTypes.map((x: any) => {
            return {
                id: x.id,
                text: t('businessClientTypes.' + x.text.replace(' ', ''))
            };
        });
        const countries = await generalStore.dispatch('getCountries');
        const cities = await generalStore.dispatch('getCities');
        const channels = await generalStore.dispatch('getChannels');
        const priceTypes = await generalStore.dispatch('getPriceTypes');
        swal.close();
        const currencies = computed<Currency[]>(() => generalStore.getters.currencies);
        const companyLogoSrc = ref('');
        const photoInstructionSrc = ref('');
        const newSavedImage = ref('');
        const cropperLib = ref();
        const cropperInstructionLib = ref();
        const sortAsc = ref(true);
        const sortParam = ref('Id');
        const orderProducts = ref([]);

        const businessSearchSystems: IdName[] = [];
        for (const enumMember in searchSystem) {
            const parsedValue = parseInt(enumMember, 10);
            if (parsedValue >= 0) {
                businessSearchSystems.push({ id: parsedValue.toString(), name: searchSystem[enumMember] });
            }
        }
        function addBusinessClientContact() {
            businessClient.value?.businessClientContacts.push(new BusinessClientContact());
        }
        async function deleteContact(index: number) {
            const swalResult = await swal.fire({
                icon: 'info',
                title: t('delete.alert'),
                customClass: {
                    confirmButton: 'stopsale-button-wrapper popup-bookit-button my-1 px-5',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: t('businessClient.deleteContact.delete'),
                cancelButtonText: t('businessClient.deleteContact.undelete')
            });
            if (swalResult.isConfirmed) {
                businessClient.value?.businessClientContacts.splice(index, 1);
            }
        }
        function hasContactError(rowIndex: number, field: string) {
            if (props.clientContactError == null) {
                return false;
            }
            const item = props.clientContactError.find(x => x.index == rowIndex);
            if (item != null) {
                if (field == 'firstName') {
                    return item.firstName;
                }
                if (field == 'lastName') {
                    return item.lastName;
                }
                if (field == 'mobile') {
                    return item.mobile;
                }
                if (field == 'officePhone') {
                    return item.officePhone;
                }
                if (field == 'email') {
                    return item.email;
                }
            }
            return false;
        }
        function hasErrorsClientDetails(field: string) {
            if (!props.errorsClientDetails) {
                return false;
            }
            if (props.errorsClientDetails.includes(field)) {
                return true;
            }
            return false;
        }
        function addDiscountCombination() {
            businessClient.value?.businessClientDiscountCombinations.push(new BusinessClientDiscountCombinations());
        }
        async function validateCommission(event: any, indexCombination: number) {
            const value = Number(event.target.value.replace(/[^0-9.]/g, ''));
            let valueToChage = 0;
            if (value > 100) {
                valueToChage = 100;
            } else if (value < 0) {
                valueToChage = 0;
            } else {
                valueToChage = value;
            }
            event.target.value = valueToChage;
            if (businessClient.value) {
                businessClient.value.businessClientDiscountCombinations[indexCombination].discount = valueToChage;
            }
        }
        async function deleteCombination(index: number) {
            const swalResult = await swal.fire({
                icon: 'info',
                title: t('delete.alert'),
                customClass: {
                    confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: t('editProductPage.cancelEdit.alertConfirm'),
                cancelButtonText: t('editProductPage.cancelEdit.alertCancel')
            });
            if (swalResult.isConfirmed) {
                businessClient.value?.businessClientDiscountCombinations.splice(index, 1);
            }
        }
        function clearImage(index: number) {
            businessClient.value.businessClientPhotoInstructions.splice(index, 1);
        }
        const uploadingImageValidation = async (e: any) => {
            swal.showLoading();
            let file = e.target.files[0];
            const fileTypes: string[] = ['image/png'];
            if (file) {
                if (file.size > 5242880) {
                    swal.fire({
                        icon: 'error',
                        text: 'File size should be less or equal to 5 MB'
                    });
                    file = null;
                    swal.close();
                    return;
                }
                if (!fileTypes.find(t => t === file.type)) {
                    swal.fire({
                        icon: 'error',
                        text: 'Allowing file type: png'
                    });
                    file = null;
                    return;
                }
                if (typeof FileReader === 'function') {
                    const reader = new FileReader();
                    reader.onload = (event: any) => {
                        const logo: BusinessClientPhotoInstruction = new BusinessClientPhotoInstruction();
                        logo.logo = event.target.result;
                        businessClient.value.businessClientPhotoInstructions.push(logo);
                    };
                    reader.readAsDataURL(file);
                }
            }
            swal.close();
        };
        function routeToEdit(field: string) {
            if (businessClient.value && businessClient.value.id) {
                if (field === 'businessClientName') {
                    router.push({
                        name: 'EditTranslation',
                        params: {
                            entity: 'BusinessClient',
                            id: businessClient.value.id,
                            type: field
                        }
                    });
                }
            }
        }
        function clearCompanyLogo() {
            newSavedImage.value = '';
            companyLogoSrc.value = '';
        }

        function clearPhotoInstruction() {
            newSavedImage.value = '';
            photoInstructionSrc.value = '';
        }
        async function uploadingCompanyLogoValidation(e: any) {
            swal.showLoading();
            let file = e.target.files[0];
            const fileTypes: string[] = ['image/jpeg', 'image/png'];
            if (file) {
                if (file.size > 5242880) {
                    swal.fire({
                        icon: 'error',
                        text: 'File size should be less or equal to 5 MB'
                    });
                    file = null;
                    return;
                }
                if (!fileTypes.find(t => t === file.type)) {
                    swal.fire({
                        icon: 'error',
                        text: 'Allowing file types: jpeg, jpg, png'
                    });
                    file = null;
                    return;
                }
                if (typeof FileReader === 'function') {
                    const reader = new FileReader();
                    reader.onload = (event: any) => {
                        companyLogoSrc.value = event.target.result;
                    };
                    reader.readAsDataURL(file);
                }
            }
            swal.close();
            e.target.value = '';
        }
        async function uploadingPhotoInstructionValidation(e: any) {
            swal.showLoading();
            let file = e.target.files[0];
            const fileTypes: string[] = ['image/jpeg', 'image/png'];
            if (file) {
                if (file.size > 5242880) {
                    swal.fire({
                        icon: 'error',
                        text: 'File size should be less or equal to 5 MB'
                    });
                    file = null;
                    return;
                }
                if (!fileTypes.find(t => t === file.type)) {
                    swal.fire({
                        icon: 'error',
                        text: 'Allowing file types: jpeg, jpg, png'
                    });
                    file = null;
                    return;
                }
                if (typeof FileReader === 'function') {
                    const reader = new FileReader();
                    reader.onload = (event: any) => {
                        photoInstructionSrc.value = event.target.result;
                    };
                    reader.readAsDataURL(file);
                }
            }
            swal.close();
            e.target.value = '';
        }
        const saveCompanyLogo = async () => {
            swal.showLoading();
            const { canvas } = cropperLib.value.getResult();
            if (canvas) {
                const fd = new FormData();
                canvas.toBlob(
                    async (blob: any) => {
                        fd.append('file', blob);
                        const uploadResponse = await api.uploadBusinessClientCompanyLogo(fd, businessClient.value.id);
                        if (uploadResponse.errorMessage) {
                            await swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: uploadResponse.errorMessage });
                            return;
                        }
                        if (uploadResponse.data) {
                            const companyLogo = uploadResponse.data;
                            companyLogoSrc.value = '';
                            newSavedImage.value = companyLogo;
                            businessClient.value.companyLogoUrl = uploadResponse.data;
                            swal.close();
                        }
                    },
                    'image/jpeg',
                    0.9
                );
            }
        };
        function uploadFile(e: any, imageInputId: string): void {
            e.target.value = '';
            newSavedImage.value = '';
            document.getElementById(imageInputId)?.click();
        }
        function startUploadImage(e: any, imageInputId: string) {
            uploadFile(e, imageInputId);
        }
        function uploadPhotoInstruction(e: any, imageInputId: string): void {
            e.target.value = '';
            photoInstructionSrc.value = '';
            document.getElementById(imageInputId)?.click();
        }
        const savePhotoInstruction = async () => {
            swal.showLoading();
            const { canvas } = cropperInstructionLib.value.getResult();
            if (canvas) {
                const fd = new FormData();
                canvas.toBlob(
                    async (blob: any) => {
                        fd.append('file', blob);
                        const uploadResponse = await api.uploadBusinessClientPhotoInstruction(fd, businessClient.value.id);
                        if (uploadResponse.errorMessage) {
                            await swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: uploadResponse.errorMessage });
                            return;
                        }
                        if (uploadResponse.data) {
                            const photoInstruction = uploadResponse.data;
                            photoInstructionSrc.value = '';
                            businessClient.value.businessClientPhotoInstructions.push(photoInstruction);
                            swal.close();
                        }
                    },
                    'image/jpeg',
                    0.9
                );
            }
        };
        async function deleteInstructionPhoto(photoId: string) {
            const swalAction = await swal.fire({
                icon: 'info',
                text: t('business-client.alert.delete-InstructionPhoto'),
                customClass: {
                    confirmButton: 'delete-button popup-bookit-button my-1 px-4',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: t('alert.yesDeleteIt'),
                cancelButtonText: t('button.cancel')
            });
            if (!swalAction.isConfirmed) {
                return;
            }
            await q.delay(200);
            swal.showLoading();
            const uploadResponse = await api.deleteInstructionPhoto(businessClient.value.id, photoId);
            if (uploadResponse.errorMessage) {
                await swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: uploadResponse.errorMessage });
                return;
            }
            swal.close();
            const index = businessClient.value.businessClientPhotoInstructions.findIndex(x => x.id == photoId);
            if (index >= 0) {
                businessClient.value.businessClientPhotoInstructions.splice(index, 1);
            }
        }

        async function disactivateOrActivateBusinessClient(evt: Event) {
            evt.preventDefault();
            const isActive = businessClient.value.active;
            const productActivateOrDisactivateMessage = isActive ? t('business-client.deactivate') : t('business-client.activate');
            const swalAction = await swal.fire({
                icon: 'info',
                text: productActivateOrDisactivateMessage,
                customClass: {
                    confirmButton: isActive
                        ? 'stop-sale-button popup-bookit-button my-1 px-4'
                        : 'green-button popup-bookit-button my-1 px-4',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: isActive ? t('alert.yesDeactivate') : t('alert.yesActivate'),
                cancelButtonText: t('button.cancel')
            });
            if (!swalAction.isConfirmed) {
                return;
            }
            await q.delay(200);
            swal.showLoading();
            const apiPromise = api.ActivateOrDisactivateBusinessClient(businessClient.value.id);
            await q.delay(400);
            const response = await apiPromise;
            if (response.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: response.errorMessage
                });
                return;
            }
            businessClient.value.active = !businessClient.value.active;
            swal.fire({
                position: 'center',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            });
        }
        const getArrowClass = (sort: string) => {
            if (sort == sortParam.value) {
                if (sortAsc.value) {
                    return 'top-sort-arrow';
                } else {
                    return 'bottom-sort-arrow';
                }
            }
            return 'standart-sort-arrow';
        };
        function changePage(pageNumber: number) {
            if (props.isNew) {
                if (pageNumber != 1) {
                    return;
                }
            }
            emit('activeBtn', pageNumber);
        }

        return {
            businessClient,
            officialClubs,
            businessClientTypesTranslations,
            countries,
            cities,
            addBusinessClientContact,
            deleteContact,
            addDiscountCombination,
            currencies,
            validateCommission,
            channels,
            priceTypes,
            deleteCombination,
            clearImage,
            uploadingImageValidation,
            routeToEdit,
            hasContactError,
            hasErrorsClientDetails,
            contactsHasDuplicates,
            businessSearchSystems,
            searchSystem,
            passwordFieldType,
            uploadingCompanyLogoValidation,
            saveCompanyLogo,
            companyLogoSrc,
            clearCompanyLogo,
            startUploadImage,
            cropperLib,
            cropperInstructionLib,
            photoInstructionSrc,
            uploadPhotoInstruction,
            savePhotoInstruction,
            clearPhotoInstruction,
            uploadingPhotoInstructionValidation,
            deleteInstructionPhoto,
            disactivateOrActivateBusinessClient,
            getArrowClass,
            orderProducts,
            changePage
        };
    }
});
